// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Use global color variables
@use './colors.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sytex-login-primary: mat.define-palette(mat.$indigo-palette);
$sytex-login-accent: mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sytex-login-warn: mat.define-palette(mat.$red-palette);

// Define default font family.
$sytex-login-typography: mat.define-typography-config(
  $font-family: '"Nunito", sans-serif'
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sytex-login-theme: mat.define-light-theme(
  (
    color: (
      primary: $sytex-login-primary,
      accent: $sytex-login-accent,
      warn: $sytex-login-warn
    ),
    typography: $sytex-login-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sytex-login-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  color: var(--syt-font-color);

  * {
    box-sizing: border-box;
    scrollbar-color: rgba(11, 20, 26, 0.2) transparent;
    scrollbar-width: thin;
  }

  a {
    text-decoration: none;

    &:link {
      color: inherit;
    }

    &:visited {
      color: inherit;
    }

    &:hover {
      color: inherit;
    }

    &:active {
      color: inherit;
    }
  }
}

* {
  font-family: 'Nunito', sans-serif;

  &::before,
  &::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/* Font awesome icon overwrite */
fa-icon,
fa-duotone-icon {
  display: flex;
}

/* Tooltip */
.mat-mdc-tooltip-panel {
  pointer-events: none;
}

/* Cookie consent */
.cc-window {
  font-family: 'Nunito', sans-serif !important;
}
