$vars: (
  surface-color: #FFFFFF,
  background-menu-color: #F2F7FA,
  greyscale-1-color: #2D3C3E,
  greyscale-2-color: #5A7077,
  greyscale-3-color: #8EA4AA,
  greyscale-4-color: #CAD8DA,
  greyscale-5-color: #F9FCFC,
  primary-color: #00CCCC,
  accent-dark-color: #4954B8,
  green-accent-color: #5BD65E,
  green-accent-color-10: #5BD65E1A,
  red-accent-color: #FF4D4D,
  red-accent-color-10: #FF4D4D1A,
  green-color: #4caf50,
  green-color-10: #4caf501a,
  red-color: #f44336,
  red-color-10: #f443361a,
  // New DD
  accent-500: #606ff7,
  accent-300: #b0b5fc,
  accent-200: #cacdfe,
  accent-100: #e2e4fe,

  neutral-500: #2d3c3e,
  neutral-400: #5a7077,
  neutral-300: #8ea4aa,
  neutral-200: #cad8da,
  neutral-100: #e9edf0,
  neutral-50: #eff6f9,

  font-color: #2d3c3e,
  contrast-font-color: #fff,

  border-color: #cad8da,
  dark-border-color: #8ea4aa,

  // Legacy
  main-color: #00bcd4,
  accent-color: #606ff7,

  faded-font-color: #8ea4aa,
  hover-font-color: #0e1213,
  field-label-font-color: #8ba1a7,

  bg-color: #fff,
  backdrop-bg-color: rgba(0, 0, 0, 0.25),
  canvas-bg-color: #f5f5f5,
  tooltip-bg-color: rgba(0, 0, 0, 0.75),
  bg-faded-color: rgba(255, 255, 255, 0.5),
  bg-hover-color: #f0f0f0,
  shadow-color: rgba(0, 0, 0, 0.15),
  highlight-bg-color: #f7fcfc,

  button-font-color: #5c7279,
  button-bg-hover-color: rgba(0, 0, 0, 0.05),
  button-bg-active-color: rgba(0, 0, 0, 0.1),
  table-head-bg-color: #f1f8f8,
  table-odd-row-bg-color: #f7fcfc,
  table-row-bg-selected-color: rgba(0, 188, 212, 0.2),
  table-row-bg-hover-color: #e9f5f5,
  table-row-bg-deleted-color: rgba(244, 67, 54, 0.2),
  light-outline-color: rgba(101, 102, 103, 0.05),
  dark-outline-color: rgba(0, 0, 0, 0.1),
  main-outline-color: rgba(0, 188, 212, 0.5),
  warn-outline-color: rgba(244, 67, 54, 0.5),
  contrast-bg-color: #46585b,

  default-status-color: #8faab1,

  box-shadow-low: 0 0 6px 0 rgba(0, 0, 0, 0.15),
  box-shadow-medium: 0 4px 12px 0 rgba(0, 0, 0, 0.25),
  box-shadow-high: 0px 16px 48px 0 rgba(0, 0, 0, 0.5),
  darker-border-color: rgba(0, 0, 0, 0.15),
  warning-bg-color: #fff3cd,

  color-white: #fff,
  color-black: #000,
  color-blue: #2196f3,
  color-pale-blue: #4695b9,
  color-green: #4caf50,
  color-grey: #5c7279,
  color-light-blue: #00bcd4,
  color-light-green: #b5cc26,
  color-light-grey: #8ba1a7,
  color-soft-grey: #dddddd,
  color-red: #f44336,
  color-pink: #f49393,
  color-orange: #ffa825,
  color-yellow: #ffd371,

  color-budget: #ffd371,
  color-client: #f49393,
  color-file-document: #47c9bd,
  color-form: #3bb7f2,
  color-form-template: #3bb7f2,
  color-fleet-member: #cc92f0,
  color-full-shipment: #9597e2,
  color-material-operation: #9597e2,
  color-material-stock: #9597e2,
  color-network-element: #fbac80,
  color-project: #47c9bd,
  color-purchase-order: #ffd371,
  color-quotation: #ffd371,
  color-role: #47c9bd,
  color-sales-order: #ffd371,
  color-site: #607ee0,
  color-site-access-request: #607ee0,
  color-site-access-request-type: #47c9bd,
  color-staff: #cc92f0,
  color-stopper: #f44336,
  color-supplier: #f49393,
  color-task: #47c9bd,
  color-task-template: #47c9bd,
  color-timer: #47c9bd,
  color-tracking-time-frame: #cc92f0,
  color-workflow: #47c9bd,
  color-workflow-template: #47c9bd,
  color-workspace: #f49393
);

:root {
  @each $var, $val in $vars {
    --syt-#{$var}: #{$val};
  }
}